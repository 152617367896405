import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AdminServerDataSource } from '../../../lib/ng2-smart-table-server-data-source/admin-server.data-source';
import { Provider } from '../../providers/provider';

@Component({
  selector: 'app-dispatchers',
  templateUrl: './dispatchers.component.html',
  styleUrls: [
    '../../../themes/primeng-table.scss',
    './dispatchers.component.scss'
  ]
})
export class DispatchersComponent implements OnInit {
  users: AdminServerDataSource;
  selectedUser: AdminServerDataSource;
  yesNoList = [];
  loading = false;
  transportationCompanyMapper: any;
  transportationCompanyFilterList = [];
  transportationCompanies: Provider[];

  tableConfig = {
    columns: {
      first_name: {
        title: 'First Name'
      },

      last_name: {
        title: 'Last Name'
      },

      email: {
        title: 'Email'
      },

      transportation_company_id: {
        title: 'Transportation Company',
        filter: {
          type: 'list',
          config: {
            list: this.transportationCompanyFilterList
          }
        }
      },

      confirmed_at: {
        title: 'Confirmed?',
        filter: {
          type: 'list',
          config: {
            list: this.yesNoList
          }
        }
      },

      active: {
        title: 'Active?',
        filter: {
          type: 'list',
          config: {
            list: this.yesNoList
          }
        }
      },

      created_at: {
        title: 'Created On',
        width: '200px',
        filter: {
          type: 'list',
          config: {
            list: [
              {value: 'january', title: 'January'},
              {value: 'february', title: 'February'},
              {value: 'march', title: 'March'},
              {value: 'april', title: 'April'},
              {value: 'may', title: 'May'},
              {value: 'june', title: 'June'},
              {value: 'july', title: 'July'},
              {value: 'august', title: 'August'},
              {value: 'september', title: 'September'},
              {value: 'october', title: 'October'},
              {value: 'november', title: 'November'},
              {value: 'december', title: 'December'}
            ]
          }
        }
      }
    },

    actions: {
      add: false,
      edit: false,
      delete: false
    }
  };

  keys = Object.keys(this.tableConfig.columns)

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    protected http: HttpClient
  ) {}

  ngOnInit() {
    this.yesNoList.push({value: true, title: 'Yes'});
    this.yesNoList.push({value: false, title: 'No'});
    if(!this.users){ 
      const dataSource = new AdminServerDataSource(this.http, {
        endPoint: `${environment.apiurl}/users?user_filter=dispatchers`,
        formatDataFunction: this.mutateUsers.bind(this)
      });
      
      dataSource.getElements().then(data => {
        this.users = data;
      });
    }
    this.route.data.subscribe((data) => {
      this.transportationCompanyMapper = data.providers.data.reduce((map, transporation_company) => {
        map[transporation_company.id] = transporation_company.name;

        this.transportationCompanyFilterList.push({
          value: transporation_company.id,
          title: transporation_company.name
        });

        return map;
      }, {});

      this.transportationCompanies = data.providers.data;
    });
  }  

  loadUsers(){
    this.users = new AdminServerDataSource(this.http, {
      endPoint: `${environment.apiurl}/users?user_filter=dispatchers`,
      formatDataFunction: this.mutateUsers.bind(this)
    });
  }

  public onRowSelect(event) {
    this.router.navigate(['/users', event.data.id]);
  }

  private mutateUsers(users) {
    users.forEach(user => {
      user['transportation_company_id'] =  this.transportationCompanyMapper[user['transportation_company_id']] || '-';
      user['created_at'] = moment(user['created_at']).format('LLL');
      user['confirmed_at'] = user['confirmed_at'] ? 'Yes' : 'No';
      user['active'] = user['active'] ? 'Yes' : 'No';
    });
  }

}
