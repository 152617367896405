import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AdminServerDataSource } from '../../../lib/ng2-smart-table-server-data-source/admin-server.data-source';

@Component({
  selector: 'app-care-coordinators',
  templateUrl: './care-coordinators.component.html',
  styleUrls: [
    '../../../themes/primeng-table.scss',
    './care-coordinators.component.scss'
  ]
})

export class CareCoordinatorsComponent implements OnInit {
  users: AdminServerDataSource;
  selectedUser: AdminServerDataSource;
  yesNoList = [];
  loading = false;
  hospitalMapper: any;
  hospitalFilterList = [];

  tableConfig = {
    columns: {
      first_name: {
        title: 'First Name'
      },

      last_name: {
        title: 'Last Name'
      },

      email: {
        title: 'Email'
      },

      hospital_id: {
        title: 'Organization',
        filter: {
          type: 'list',
          config: {
            list: this.hospitalFilterList
          }
        }
      },

      role: {
        title: 'Admin User?',
        filter: {
          type: 'list',
          config: {
            list: this.yesNoList
          }
        }
      },

      confirmed_at: {
        title: 'Confirmed?',
        filter: {
          type: 'list',
          config: {
            list: this.yesNoList
          }
        }
      },

      active: {
        title: 'Active?',
        filter: {
          type: 'list',
          config: {
            list: this.yesNoList
          }
        }
      },

      created_at: {
        title: 'Created On',
        width: '200px',
        filter: {
          type: 'list',
          config: {
            list: [
              {value: 'january', title: 'January'},
              {value: 'february', title: 'February'},
              {value: 'march', title: 'March'},
              {value: 'april', title: 'April'},
              {value: 'may', title: 'May'},
              {value: 'june', title: 'June'},
              {value: 'july', title: 'July'},
              {value: 'august', title: 'August'},
              {value: 'september', title: 'September'},
              {value: 'october', title: 'October'},
              {value: 'november', title: 'November'},
              {value: 'december', title: 'December'}
            ]
          }
        }
      }
    },

    actions: {
      add: false,
      edit: false,
      delete: false
    }
  };

  keys = Object.keys(this.tableConfig.columns)

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    protected http: HttpClient
  ) {}

  ngOnInit() {
    this.yesNoList.push({value: true, title: 'Yes'});
    this.yesNoList.push({value: false, title: 'No'});
    if(!this.users){ 
      const dataSource = new AdminServerDataSource(this.http, {
        endPoint: `${environment.apiurl}/users?user_filter=care_coordinators`,
        formatDataFunction: this.mutateUsers.bind(this)
      });
      
      dataSource.getElements().then(data => {
        this.users = data;
      });
    }
    this.route.data.subscribe((data) => {
      this.hospitalMapper = data.hospitals.data.sort((a, b) => (a.name < b.name ? -1 : 1)).reduce((map, hospital) => {
        map[hospital.id] = hospital.name;
  
        this.hospitalFilterList.push({
          value: hospital.id,
          title: hospital.name
        });
  
        return map;
      }, {});
    });
  }
  
  loadUsers() {
    const dataSource = new AdminServerDataSource(this.http, {
      endPoint: `${environment.apiurl}/users?user_filter=care_coordinators`,
      formatDataFunction: this.mutateUsers.bind(this)
    });
    
    dataSource.getElements().then(data => {
      this.users = data;
    });
  }

  public onRowSelect(event) {
    this.router.navigate(['/users', event.data.id]);
  }

  private mutateUsers(users) {
    users.forEach(user => {
      user['hospital_id'] =  this.hospitalMapper[user['hospital_id']] || '-';
      user['created_at'] = moment(user['created_at']).format('LLL');
      user['confirmed_at'] = user['confirmed_at'] ? 'Yes' : 'No';
      user['active'] = user['active'] ? 'Yes' : 'No';
      user['role'] = user['is_admin_cc'] === true ? 'Yes' : 'No';
    });
  }
}
