<app-loader *ngIf="loading"></app-loader>

<div class="row">
  <div class="col-xs-12">
    <p-table
    [value]="users"
    selectionMode="single"
    [(selection)]="selectedUser"
    (onRowSelect)="onRowSelect($event)"
    (onRowUnselect)="null" 
    [paginator]="true"
    [rows]="10"
    stateStorage="session" 
    stateKey="state-session-users-dispatchers"
    >
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let key of keys" pSortableColumn="{{ key }}" [style.width]="tableConfig.columns[key].width">
          {{ tableConfig.columns[key].title }} <p-sortIcon field="{{ key }}"></p-sortIcon>
        </th>
      </tr>
      <tr>
        <th *ngFor="let key of keys" style="width:10%">
          <p-columnFilter 
          type="text" 
          field="{{key}}" 
          placeholder="{{tableConfig.columns[key].title}}" 
          ariaLabel="Filter {{tableConfig.columns[key].title}}" 
          />
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-user>
      <tr [pSelectableRow]="user">
        <td *ngFor="let key of keys">{{ user[key] }}</td>
      </tr>
    </ng-template>
  </p-table>
  </div>
</div>
