export const environment = {
  production: true,
  fullStoryId: null,
  apiurl: 'https://staging.rideroundtrip.com/api/v1/admin',
  apiv3url: 'https://staging.rideroundtrip.com/api/v3',
  appurl: 'https://staging.rideroundtrip.com/',
  googleApiKey: 'AIzaSyDrzDMACuRTdtIuWHmMbrvv0H3ip5WQQ_c',
  sqs_queue_url: 'https://sqs.us-east-1.amazonaws.com/146263503364/roundtrip-webhooks-v2-staging.fifo',
  launchDarklyClientApiKey: '60f722445bf2df25322ea4ba',
  auth0ClientId: 'TVdPpm007USH7FpspYp0xqAzqbBz0Psu',
  auth0Domain: 'login-staging.rideroundtrip.com',
  adminCreation: 'EVERYONE'
};
